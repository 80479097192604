export const Stage = {
    DEV: "dev",
    BETA: "beta",
    GAMMA: "gamma",
    PREPROD: "preprod",
    PROD: "prod"
}

export const getStage = () => {
    return 'dev'
}