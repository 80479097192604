import {getStage, Stage} from "src/components/utils";

export const ConnectQueueFragment = () => {
    initializeCommunicationToAmazonConnect(...getWindowAndSnippedIdsByStage());
    return <span></span>
}

const initializeCommunicationToAmazonConnect = (windowId, snippetId) => {
    (function(w, d, x, id){
        var s=d.createElement('script');
        s.src='https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js';
        s.async=1;
        s.id=id;
        d.getElementsByTagName('head')[0].appendChild(s);
        w[x] =  w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) };
    })(window, document, 'amazon_connect', windowId);
    amazon_connect('styles', { iconType: 'VOICE', openChat: { color: '#ffffff', backgroundColor: '#123456' }, closeChat: { color: '#ffffff', backgroundColor: '#123456'} });
    amazon_connect('snippetId', snippetId);
    amazon_connect('supportedMessagingContentTypes', [ 'text/plain', 'text/markdown', 'application/vnd.amazonaws.connect.message.interactive', 'application/vnd.amazonaws.connect.message.interactive.response' ]);
}

const getWindowAndSnippedIdsByStage = () => {
    const envStage = getStage();
    switch (envStage){
        case Stage.DEV:
            return [
                'c34f9cfa-c15a-47a5-b662-5f25f59a297b',
                'QVFJREFIakZhMVo2ZGZmSXpGSnpJS2lYakthYVBxMmJIU0ZPbnhET3AyalJDV1F3UWdGTkR2aExLUlRYaDVReTZKOUtBUDc4QUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNbnBmTHpMRGh0WTdZdytnOUFnRVFnQ3NhWk9RTGRsbHFtMXJBV0lncDRFVXp6eHJlbkdMRFhWZjZENHkzc1d6MHNaNkwrSE1PWVNjam9kV0s6OnNNMCs2MVpPRzFWd3E5WEFmK2pyRy84NW1mNUY2YjVyV2V2NFFxbG9RdEZVZUhJVTFmT0cwaVgrb0p6aFhubTcwblpOK3NXNE1TeHBHSmpWakZOZ0Jic0U0emd6WTA2SkZpenltMjdVdHF3Y3lkNDFGWXROcXZ4QUhiSG1pTUZVZk5WUFZoeXRlc2R1dDhGK21pMW10V2VmT1dRV3VDRT0='
            ]
        case Stage.BETA:
            return [
                'c34f9cfa-c15a-47a5-b662-5f25f59a297b',
                'QVFJREFIakZhMVo2ZGZmSXpGSnpJS2lYakthYVBxMmJIU0ZPbnhET3AyalJDV1F3UWdGTkR2aExLUlRYaDVReTZKOUtBUDc4QUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNbnBmTHpMRGh0WTdZdytnOUFnRVFnQ3NhWk9RTGRsbHFtMXJBV0lncDRFVXp6eHJlbkdMRFhWZjZENHkzc1d6MHNaNkwrSE1PWVNjam9kV0s6OnNNMCs2MVpPRzFWd3E5WEFmK2pyRy84NW1mNUY2YjVyV2V2NFFxbG9RdEZVZUhJVTFmT0cwaVgrb0p6aFhubTcwblpOK3NXNE1TeHBHSmpWakZOZ0Jic0U0emd6WTA2SkZpenltMjdVdHF3Y3lkNDFGWXROcXZ4QUhiSG1pTUZVZk5WUFZoeXRlc2R1dDhGK21pMW10V2VmT1dRV3VDRT0='
            ]
        case Stage.GAMMA:
            return [
                '176d0ea4-a839-4ae4-96ae-ba738a7242d5',
                'QVFJREFIakZhMVo2ZGZmSXpGSnpJS2lYakthYVBxMmJIU0ZPbnhET3AyalJDV1F3UWdFNGtNTll6bXdCV1VYK1RabC9hY2tYQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNQW02WTVieklBSVNhODJJdUFnRVFnQ3NrYVc2bzQ2QWVBTVl0allxOHhValVMYWJRajBqcjc1YWw4Y3B2SjRKa3R5aGVsZFFhYVQ2MVNmMHM6OjFzblpqbk1rMC9CZ0l3bE5TVDVJZUFNVUJLdHNjTHEwVjMxRHNqMnFWbWpoYlZuVTZKbSt0bDR2cmVDUFBxRVZVbXZZN2Z0WG5NUlBzK2JBR0Z1YkFzTkJKTStmYnZ6U1o0blJGUlUzZExGR2tmYXNmTHZ4YloxTmxreFBnaVRYcnVZNGhaNmdPRjNMblYwcHFKQnQyLzZTY01JRlp0RT0='
            ]
        case Stage.PREPROD:
            return [
                'b6500fe9-29aa-4c6d-8f58-fabcb89db7ae',
                'QVFJREFIakZhMVo2ZGZmSXpGSnpJS2lYakthYVBxMmJIU0ZPbnhET3AyalJDV1F3UWdGVzV6ZE9qVitzYzMwbWxDb2QxeEsvQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNQjNlWEtjbW8xT3UzenIzTEFnRVFnQ3V5SVB0QWpyTWJPNVl3azVsczdDcTV3cGNBREJRNTZmM0cxS055Njh0YWtqc2Y3K0xJRzRXZ3JZMWQ6OlViMlM2VlcwQkN0dDJqemhRckUwL0xaRy8xejFjMUoxTzZOcTFkQlpjVXdxV3lzcGRzSGc4bmJYMG1PblloY2JTRVBRbU55MndRMDQrTDdmdllMSVNXb2VCQmt0ZENTVHE0N2ZnVkhEVGYxWnFNR1NkS3pEZUY2elhwL1kyZGltWjlNWEovT1RTL1VDMk9QRmo2Y0FZYTEvWWFqL2l5MD0='
            ]
        case Stage.PROD:
            return [
                '',
                ''
            ]
        default:
            console.error(`Invalid Stage provided: (${envStage}); Unable to initialize communication with Connect Instance`)
            return [
                '',
                ''
            ]
    }
}